.skillsContainer {
  margin-left: 5%;
  margin-right: 5%;
  max-width: 90%;
  border: 1.5rem solid rgba(0, 0, 255, 0.508);
  border: 1.5rem solid white;
}

.skillsHeader {
  padding-left: 2rem;
  padding-bottom: 1rem;
  font-size: 3.5rem;
}

.skillsSectionTitle {
  padding-bottom: 1rem;
  font-size: 2.5rem;
}

.skillsCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillDiv {
  display: flex;
  padding-bottom: 0.5rem;
}

.skillsText {
  padding-top: 0.2rem;
  padding-left: 0.5rem;
  font-size: 1.5rem;
}

.dark {
  .skillsContainer {
    border-image: linear-gradient( to top, #252525, rgba(0, 0, 255, 0.508), rgba(0, 0, 255, 0.508), #252525 ) 1;
  }

  .figmaIcon {
    color: #ffffff;
  }
}

.light {
  .skillsContainer {
    border-image: linear-gradient( to top, #f0f0f0, #0080ffd7, #0080ffd7, #f0f0f0 ) 1;
  }

  .figmaIcon {
    color: #1a1a1a;
  }
}