.projectContainer {
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  max-width: 90%;
  border: 1.5rem solid rgba(0, 0, 255, 0.508);
}

.projects{
  padding-left: 2rem;
  padding-bottom: 2rem;
  font-size: 3.5rem;
}

.dark {
  .projectContainer {
    border-image: linear-gradient( to bottom right, #252525, #252525, #252525, rgba(0, 0, 255, 0.508) ) 1;
  }
}

.light {
  .projectContainer {
    border-image: linear-gradient( to bottom right, #f0f0f0, #f0f0f0, #f0f0f0, #0080ffd7 ) 1;
  }
}
