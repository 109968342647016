.odd-one-out {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.normal {
    margin-left: auto;
    margin-right: auto;
}

.project-card {
    color: white;
    margin-bottom: 6rem;
    border-radius: 5px;
    aspect-ratio: 19 / 13.5;
}

.project-card,
.project-card header,
.project-card header img,
.project-card .project-bio,
.project-card {
    overflow: hidden;
    position: relative;
    top: 0;
    transition: all 0.47s ease-out;
}

.project-card:hover header,
.project-card:hover .project-bio {
    top: -18.75rem;
}

.project-card header {
    aspect-ratio: 38 / 18.75;
}

.project-card:hover header a:before {
    opacity: 1;
}

.project-card h1 {
    margin-top: 1.25rem;
    margin-left: 1.87rem;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 35px;
    text-align: left;
    font-weight: 300;
    bottom: 1.25rem;
    left: 1.87rem;
    z-index: 10;
    cursor: pointer;
}

.project-card h2 {
    margin-top: 0.62px;
    margin-left: 1.87rem;
    font-weight: 300;
    letter-spacing: 0;
    font-size: 1.25rem;
    text-align: left;
    font-weight: 300;
    bottom: 1.87rem;
    left: 1.87rem;
    z-index: 10;
    cursor: pointer;
}

.project-card header a {
    display: block;
    padding: 0;
    margin: 0;
}

.project-card header img {
    border-radius: 5px 5px 0 0;
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
}

.project-card:hover header img {
    top: 10.62rem;
}

.project-bio p {
    font-weight: 300;
    font-size: 1.25rem;
    letter-spacing: 0;
    line-height: 1.69rem;
    color: #fff;
    text-align: left;
    margin: 1.87rem;
}

.project-name {
    padding-top: 1rem;
}

.project-btn-div {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 1rem;
}

.project-btn {
    border: none;
    transition: all 0.2s ease-out;
}

.icon-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dark {
    .project-card {
        background-color: #1a1a1a;
        box-shadow: 15px 15px 15px rgba(0, 0, 0, 1);
    }

    .project-btn {
        background-color: #1a1a1a;
        color: #fefefe;
    }
    
    .project-btn:active {
        background-color: #1a1a1a;
        color: #fefefe;
    }
    
    .project-btn:hover {
        color: #fefefe;
        background-color: #1a1a1a;
        box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.2);
    }
}

.light {
    .project-card {
        background-color: #f0f0f0;
        box-shadow: 15px 15px 15px rgb(180, 180, 180, 1);
    }

    .project-btn {
        background-color: #f0f0f0;
    }

    .project-btn {
        background-color: #f0f0f0;
    }
    
    .project-btn:active {
        background-color: #f0f0f0;
    }
    
    .project-btn:hover {
        background-color: #f0f0f0;
        box-shadow: 0 0 5px 5px rgba(70, 70, 70, 0.2);
    }
}

@media screen and (max-width: 1300px) {
    .project-name {
        font-size: 1.75rem !important;
    }
    .project-card:hover header,
    .project-card:hover .project-bio {
        top: -14.75rem;
    }
    .project-name {
        padding-top: 0;
    }
    .project-btn-div {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@media screen and (max-width: 991px) {
    .odd-one-out {
        width: 100%;
    }
}

/* 1199 */