#welcome {
    padding-top: 1.5rem;
}

#skills{
    padding-top: 2rem;
}

#projects {
    padding-bottom: 5rem;
}

.dark {
    background-color: #252525;

    .dmSerif {
        font-family: "DmSerif";
        color: white !important;
    }
    
    .dmSans {
        font-family: "DmSans";
        color: white !important;
    }

    .bgColor {
        background-color: #252525;
    }
}

.light {
    background-color: #f0f0f0;

    .dmSerif {
        font-family: "DmSerif";
        color: black !important;
    }
    
    .dmSans {
        font-family: "DmSans";
        color: black !important;
    }

    .bgColor {
        background-color: #f0f0f0;
    }
}