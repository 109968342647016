.footerTop {
  height: 0.35rem;
  margin: 0 5% 10px;
  width: 90%;
}

.footerSpacing {
  margin-left: 5%;
  margin-right: 5%;
  max-width: 90%;
  padding-top: 0.5rem;
}

.footerCol {
    display: flex;
    justify-content: center;
}

.resumeText:hover {
    color: gray !important;
}

.dark {
  .footerTop {
    background-color: #1a1a1a;
  }
}

.light {
  .footerTop {
    background-color: #b4b4b4;
    border: none;
  }
}