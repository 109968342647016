.welcome {
    font-size: 4.5rem;
    padding-top: 12rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
}

.welcomeContainer {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    max-width: 90%;
    border: 1.5rem solid white;
    border-radius: 15px;
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.bioTxt {
    padding-left: 2rem;
    line-height: 50px;
}

.bioIndent{
    margin-left: 15px;
}

.linkIcon{
    color: #0A66C2;
    padding-left: 2rem;
}

.gitIcon{
    padding-left: 1rem;
}

.resumeTxt{
    padding-left: 1rem;
}

.resumeTxt:hover {
    color: gray !important;
}

.bellowDiv{
    display: flex;
}

.arrowIcon{
    padding-top: 1.3rem;
    padding-left: 1rem;
}

.picture-col {
    display: flex;
    justify-content: center;
    padding-top: 8rem;
}

.picOfMe {
    width: 20rem;
    aspect-ratio: 2/3;
    border-radius: 10px;
}

.dark {
    .welcomeContainer {
        border-image: linear-gradient( to bottom right, rgba(0, 0, 255, 0.508), #252525, #252525, #252525 ) 1;
    }

    .gitIcon{
        color: white;
    }

    .arrowIcon {
        color: white;
    }
}

.light {
    .welcomeContainer {
        border-image: linear-gradient( to bottom right, #0080ffd7, #f0f0f0, #f0f0f0, #f0f0f0 ) 1;
    }

    .gitIcon{
        color: black;
    }

    .arrowIcon {
        color: black;
    }
}

@media screen and (max-width: 430px) {
    .welcome {
        font-size: 3.5rem;
        padding-top: 1rem;
    }
}